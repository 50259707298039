<template>
  <div class="mt-1 p-2">
    <b-card>
      <div class="mb-1"><h2>Actions Timeline</h2></div>
      <b-overlay :show="loading" rounded="sm" no-fade>
        <b-table
          striped
          hover
          :outlined="true"
          :fields="fields"
          responsive="sm"
          class="table table-sm"
          :items="timelineActions"
          show-empty
          :empty-text="'No data found'"
          :current-page="currentPage"
          :per-page="perPage"
        >
          <template #cell(created_at)="data">
            <div class="p-25">
              {{ data.value }}
            </div>
          </template>
        </b-table>

        <div class="mx-2 mb-2">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ "Showing" }} {{ dataMeta.from }} {{ "to" }}
                {{ dataMeta.to }} {{ "of" }} {{ dataMeta.of }}
                {{ "entries" }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end mt-2"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-overlay>
    </b-card>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
} from "bootstrap-vue";
import store from "@/store";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { onUnmounted } from "@vue/composition-api";
import settingsStoreModule from "../settingsStoreModule";
import vSelect from "vue-select";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    vSelect,
    ToastificationContent,
  },

  setup() {
    const TASK_APP_STORE_MODULE_NAME = "settings";
    // Register module
    if (!store.hasModule(TASK_APP_STORE_MODULE_NAME))
      store.registerModule(TASK_APP_STORE_MODULE_NAME, settingsStoreModule);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(TASK_APP_STORE_MODULE_NAME))
        store.unregisterModule(TASK_APP_STORE_MODULE_NAME);
    });
  },

  computed: {
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },

  data() {
    return {
      timelineActions: [],
      loading: true,
      perPage: localStorage.getItem("actionShow") ?? 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [25, 50, 100],
      fields: [
        {
          key: "title",
          label: "Action",
          class: "text-left",
        },
        {
          key: "user_name_surname",
          label: "User",
          sortable: true,
          class: "text-center",
        },

        {
          key: "ip_address",
          label: "IP Adress",
          sortable: false,
          class: "text-center",
        },
        {
          key: "created_at",
          label: "Date - Time",
          sortable: true,
          class: "text-center",
        },
      ],
    };
  },

  created() {
    this.getActionTimeline();
  },

  methods: {
    getActionTimeline() {
      this.loading = true;
      store
        .dispatch("settings/getAllTimelineActions")
        .then((res) => {
          this.timelineActions = res.data;
          this.totalRows = res.data.length;
          this.loading = false;
        })
        .catch((error) => {
          this.$router.push({ name: "settings" });
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "An error occurred",
              text: "Please try again later or contact support.",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
